@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
body,
p,
div,
span,
*,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Segoe UI', 'Open Sans', Helvetica, sans-serif !important;
  letter-spacing: normal;
  /* -webkit-user-select: none; */
  /* Safari */
  /* -ms-user-select: none; */
  /* IE 10 and IE 11 */
  /* user-select: none; */
  /* Standard syntax */
}

a {
  text-decoration: none !important;
}

.spinner {
  color: #0b488e !important;
}

.selectgo {
  padding: 20px !important;
}

.roxbox {
  margin-bottom: 1rem !important;
  display: inline !important;
  background-color: aqua;
}

.roxboxNew {
  flex: 0 0 auto;
  width: 25%;
  display: inline-block;
  background-color: blueviolet;
}

.dropdown-menu.pt-0.show {
  margin-top: 12px;
  border-radius: 0px;
  margin-right: -5px;
  /* background-color: #ea4335; */
}

.modelCompnaySize {
  width: 100% !important;
}

.btnSubmitCancel {
  padding: 5px 10px !important;
  border-radius: 3px !important;
  margin: 10px 10px !important;
  width: 100px !important;
  background: #ea4335 !important;
  border-color: #ea4335 !important;
}

.gaponlqy {
  /* display: contents; */
  /* gap: 80px; */
  align-items: center;
}

/* .gaponlqqy {
  display: flex;
  align-items: baseline;
  flex-direction: row;
  gap: 10px;
} */

.gapten {
  gap: 50px;
}

.gasp {
  gap: 20px;
}

.loginBtnRS {
  display: flex !important;
  justify-content: flex-end !important;
}

.forgotBtnRS {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  cursor: pointer;
}

.no-drop {
  cursor: no-drop;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  margin-bottom: 0.125rem;
  padding-left: 30px !important;
}

.form-control:disabled {
  background-color: #fff !important;
  border-color: #b1b7c1 !important;
  opacity: 1 !important;
}

body,
p,
div,
span,
*,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Segoe UI', 'Open Sans', Helvetica, sans-serif !important;
  letter-spacing: normal;
  /* -webkit-user-select: none; */
  /* Safari */
  /* -ms-user-select: none; */
  /* IE 10 and IE 11 */
  /* user-select: none; */
  /* Standard syntax */
}

a {
  text-decoration: none !important;
}

.spinner {
  color: #0b488e !important;
}

.selectgo {
  padding: 20px !important;
}

.roxbox {
  margin-bottom: 1rem !important;
  display: inline !important;
  background-color: aqua;
}

.roxboxNew {
  flex: 0 0 auto;
  width: 25%;
  display: inline-block;
  background-color: blueviolet;
}

/* .gaponly {
  text-align: center;
 display: block;
} */

.dropdown-menu.pt-0.show {
  margin-top: 12px;
  border-radius: 0px;
  margin-right: -5px;
  /* background-color: #ea4335; */
}

.modelCompnaySize {
  width: 100% !important;
}

.btnSubmitCancel {
  padding: 5px 10px !important;
  border-radius: 3px !important;
  margin: 10px 10px !important;
  width: 100px !important;
  background: #ea4335 !important;
  border-color: #ea4335 !important;
}

.inputcheck {
  margin-left: 4px;
  margin-top: 14px;
}
.inputcheckNew {
  margin-left: -100px;
  margin-top: 14px;
}

/* .gaponlqy {
  display: flex;
  gap: 80px;
  align-items: center;
} */

/* .gaponlqqy {
  display: flex;
  align-items: baseline;
  flex-direction: row;
  gap: 10px;
} */

.gapten {
  gap: 50px;
}

.gasp {
  gap: 20px;
}

.loginBtnRS {
  display: flex !important;
  justify-content: flex-end !important;
}

.forgotBtnRS {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  cursor: pointer;
}

.no-drop {
  cursor: no-drop;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  margin-bottom: 0.125rem;
  padding-left: 30px !important;
}

.form-control:disabled {
  background-color: #fff !important;
  opacity: 1 !important;
}

/*
.menuPriviligehead {
  font-weight: 500;
  -ms-text-autospace: auto;
  opacity: initial;
  font-family: 'Nunito Sans';
  text-transform: uppercase;
  letter-spacing: .5px;
} */

.Selectlabel {
  margin-bottom: 5px;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  font-family: 'Segoe UI Semibold';
}

/*
.fs-4.fw-semibold{
  font-size: 36px !important;
  text-align: center;
} */

.Signlogo {
  width: 150px;
  height: auto;
  display: flex;
  padding-bottom: 20px;
  margin: 0 auto;
}

.TableDownload {
  position: absolute;
  display: flex;
  top: 57px;
  right: 30px;
  cursor: pointer;
}

.TableDownload .tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 4px;
  padding: 1px 0;
  font-weight: 500;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 40px;
  margin-left: -10px;
  border: 1px solid;
}

.TableDownload:hover .tooltiptext {
  visibility: visible;
}

.bg-lightLogin {
  background: url(../src/assets/images/backgroung.png) center center no-repeat fixed;
  margin-bottom: auto;
  margin-top: auto;
  background-size: cover !important;
  background-position: center;
  background-repeat: no-repeat;
}

.login {
  margin: 0 auto;
  border-radius: 5px;
  box-shadow: 0 4px 12px;
}

.sidebar-brand {
  display: flex;
  flex: 0 0 !important;
  align-items: left !important;
  justify-content: left !important;
  padding-left: 10px;
  color: var(--cui-sidebar-brand-color);
  background: var(--cui-sidebar-brand-bg);
}

.table > :not(caption) > * > * {
  text-transform: capitalize !important;
  border: 1px solid #fff;
}

.textCenter {
  font-size: 22px;
  font-weight: 600;
  font-family: Segoe UI Semibold;
  display: flex;
  /* margin: 0 auto; */
  text-transform: uppercase;
  color: #0b488e;
  padding-left: 8px;
}

.table > :not(caption) > * > * {
  text-transform: capitalize !important;
}

/* .table-striped>tbody>tr:nth-child(odd)>td, .table-striped>tbody>tr:nth-child(odd)>th {
  background-color: #eeeeee;
  text-transform: capitalize !important;
} */
/* Sidebar Menu */

.nav-group-toggle {
  margin-left: -13px;
}

/*
  .sidebar-nav .nav-group-items .nav-link {
=======

  /* .sidebar-nav .nav-group-items .nav-link {
>>>>>>> b5d21a907fbe12b07ddb860e6617f458d9aec438
    padding-left: 60px !important;
  } */

.sidebar-nav .nav-link {
  padding: 10px 20px !important;
}

/* END - Sidebar Menu */

/* Stephen */
a {
  color: #0b488e;
  text-decoration: underline;
}

a:hover {
  color: #4285f4;
  text-decoration: none;
  cursor: pointer;
}

.times {
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
  font-size: 21px !important;
  cursor: pointer;
  font-weight: 600 !important;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
  font-size: 16px;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  /* padding: 5px !important; */
  padding: 20px !important;
  font-size: 14px !important;
  color: #000000 !important;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
}

.modal-content {
  border: 1px solid #0b488e !important;
}

.companySelectoption {
  width: 100%;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: var(--cui-modal-inner-border-radius);
  border-top-right-radius: var(--cui-modal-inner-border-radius);
  border: 0px;
  background-color: #001a3c !important;
  color: #fff;
  font-size: 14px;
  padding: 15px !important;
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: right !important;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  flex-direction: row-reverse;
  align-content: space-around;
  border: 0px !important;
  padding: 5px 20px !important;
}

/* .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 65%;
  color: var(--cui-modal-color);
  pointer-events: auto;
  border: var(--cui-modal-border-width) solid var(--cui-modal-border-color);
  border-radius: var(--cui-modal-border-radius);
  outline: 0;
  margin: 0 auto;
} */

.modelBtnNo {
  color: #fff;
  background-color: rgba(255, 0, 0, 1) !important;
  border-color: rgba(255, 0, 0, 1) !important;
  border-radius: 5px;
  height: 35px;
  padding: 6px 8px;
  text-decoration: none;
  text-transform: capitalize !important;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.8px;
}

.modelBtnNo:hover {
  color: #fff;
  background-color: rgba(255, 0, 0, 0.733) !important;
  border-color: rgba(255, 0, 0, 0.733) !important;
  border-radius: 5px;
  height: 35px;
  padding: 6px 8px;
  text-decoration: none;
  text-transform: capitalize !important;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.8px;
}

.modelBtnYes {
  color: #fff !important;
  background-color: rgba(78, 165, 81, 1) !important;
  border-color: rgba(78, 165, 81, 1) !important;
  border-radius: 5px;
  height: 35px;
  padding: 6px 8px;
  text-decoration: none;
  text-transform: capitalize !important;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.8px;
  border-width: 1px;
}

.modelBtnYes:hover {
  color: #fff !important;
  background-color: rgba(78, 165, 81, 0.733) !important;
  border-color: rgba(78, 165, 81, 0.733) !important;
  border-radius: 5px;
  height: 35px;
  padding: 6px 8px;
  text-decoration: none;
  text-transform: capitalize !important;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.8px;
}

button.btn.btn-primary.modelBtnYes {
  border: 1px solid;
  box-shadow: none;
}

.modal-backdrop {
  background-color: #00001573 !important;
  opacity: 0.5 !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--cui-dropdown-link-active-color);
  text-decoration: none;
  background-color: #0b488e !important;
}

.btn-primary {
  --cui-btn-color: rgba(255, 255, 255, 0.87);
  --cui-btn-bg: #002247 !important;
  --cui-btn-border-color: #002247 !important;
  --cui-btn-hover-color: rgba(255, 255, 255, 0.87);
  --cui-btn-hover-bg: #002247 !important;
  --cui-btn-hover-border-color: #002247 !important;
  --cui-btn-focus-shadow-rgb: 75, 58, 223;
  --cui-btn-active-color: rgba(255, 255, 255, 0.87);
  --cui-btn-active-bg: #002247 !important;
  --cui-btn-active-border-color: #002247 !important;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: rgba(255, 255, 255, 0.87);
  --cui-btn-disabled-bg: #002247 !important;
  --cui-btn-disabled-border-color: #002247 !important;
}

.btn-close {
  display: none !important;
}

.loginBtnNew:enabled {
  background: #34a853;
  border-color: #34a853;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.8px;
  font-family: 'Segoe UI';
  text-transform: capitalize;
  height: 33px;
  padding: 3px 8px;
  border-radius: 5px;
  line-height: 1.5;
  color: #fff;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  border: 1px solid transparent;
}

.loginBtnNew:disabled {
  background: #d8dbe0;
  border-color: #d8dbe0;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.8px;
  font-family: 'Segoe UI';
  text-transform: capitalize;
  height: 33px;
  padding: 3px 8px;
  border-radius: 5px;
  line-height: 1.5;
  color: rgb(138, 136, 136);
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  cursor: not-allowed;
  pointer-events: none;
  border: 1px solid transparent;
  border-radius: 3px;
}

/* .sidebar-nav .nav-group.show > .nav-group-toggle::after {
  transform: rotate(180deg);
} */

/* .labelNew::after {
  background-color: white;
  border-right: 1px solid rgb(67 72 76);
  border-bottom: 1px solid rgb(67 72 76);
  width: 8px;
  display: inline-block;
  height: 8px;
  transform: rotate(145deg);
  -webkit-transform: scale(1) rotate(45deg) translate(-2px, -2px);
  -moz-transform: rotate(45deg) scale(1.0);
  -o-transform: rotate(45deg) scale(1.0);
  margin-top: 5px;
  content: "";
  margin-left: 5px;
  margin-top: 5px;
  background-image: var(--cui-sidebar-nav-group-indicator);
    background-repeat: no-repeat;
    background-position: center;
    transition: transform 0.15s;
} */

.sidebar-fixed.sidebar-narrow,
.sidebar-fixed.sidebar-narrow-unfoldable:not(:hover) {
  /* z-index: 1031; */
  /* width: var(--cui-sidebar-narrow-width); */
  width: 35px !important;
}

/* .sidebar-fixed.sidebar-narrow, .sidebar-fixed.sidebar-narrow-unfoldable{
  width: 35px;
} */

.sidebar-nav .nav-link:hover {
  color: var(--cui-sidebar-nav-link-hover-color);
  text-decoration: none !important;
  background: var(--cui-sidebar-nav-link-hover-bg);
  /* position: relative;
  left: 1px; */
}

.sidebar-fixed.sidebar-narrow-unfoldable:not(:hover),
.sidebar-fixed.sidebar-narrow {
  /* z-index: 1031;
  width: var(--cui-sidebar-narrow-width); */
  /* pointer-events:none; */
  top: 65px !important;
  width: 35px !important;
}

/* @media (min-width: 768px){
.sidebar-fixed.sidebar-narrow, .sidebar-fixed.sidebar-narrow-unfoldable:not(:hover) {

    width: 35px !important;
}
} */

.sidebar-narrow-unfoldable:not(.sidebar-end) ~ * {
  --cui-sidebar-occupy-start: 0rem !important;
}

.px-3 {
  /* padding-left: 2rem !important; */
}

.container-fluid.BreadcurmTitle {
  margin-left: 0px;
  /* background-color: red; */
}

/* 
.sidebar-toggler:active + .container-fluid.BreadcurmTitle{
  margin-left: 0px;
}

.sidebar-toggler:active ~ .container-fluid.BreadcurmTitle{
  margin-left: 200px !important;
} */

.header-toggler:not(:disabled) {
  cursor: pointer;
  color: #000;
  margin-left: -15px;
}

.TaskGreenBox {
  background: linear-gradient(to bottom right, #0aa361, #1adc88);
  width: 100%;
  height: 125px;
  border-radius: 4px;
  box-shadow: 2px 2px 4px 0 rgb(0 0 0 / 15%);
  float: left;
  border-radius: 4px 4px 0px 0px !important;
}

.TaskBlueBox {
  background: linear-gradient(to bottom right, #1f6abb, #4f9cf2);
  width: 100%;
  height: 125px;
  border-radius: 4px;
  box-shadow: 2px 2px 4px 0 rgb(0 0 0 / 15%);
  float: left;
  border-radius: 4px 4px 0px 0px !important;
}
.TaskBlueBoxNew {
  background: linear-gradient(to bottom right, #36b1dc, #4f9cf2);
  width: 100%;
  height: 125px;
  border-radius: 4px;
  box-shadow: 2px 2px 4px 0 rgb(0 0 0 / 15%);
  float: left;
  border-radius: 4px 4px 0px 0px !important;
}
.WhiteBoxGreentext {
  height: 60px;
  border-radius: 0px 0px 4px 4px !important;
  text-align: center;
  font-size: 18px;
  background-color: white;
  color: hsl(154, 88%, 34%);
  padding: 4%;
  display: flow-root;
}

.WhiteBoxBluetext {
  height: 60px;
  border-radius: 0px 0px 4px 4px;
  text-align: center;
  font-size: 18px;
  background-color: white;
  color: #1f6abb;
  padding: 4%;
  display: flow-root;
}

.counttext {
  font-size: 15px;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  margin: -10px 0px 40px 0px;
}

.number {
  font-size: 48px;
  text-align: center;
  padding: 0px 0px 0px 0px;
  color: #ffffff !important;
}

.FooterText {
  font-size: 12px;
  margin-left: 45px;
}

.FootercopyRight {
  font-size: 12px;
  margin-right: 25px;
}

/* .card {
    box-shadow: 4px 0px 4px 0px rgb(0 0 0 / 15%) !important;
    --cui-card-border-color: 0px;
  } */

input,
input::placeholder {
  font-size: 14px;
}

/* END */

/* jai */

.loginBtn {
  background: #34a853;
  border-color: #34a853;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.8px;
  font-family: 'Segoe UI';
  /* text-transform: uppercase; */
  height: 33px;
  padding: 3px 8px;
  border-radius: 5px;
  line-height: 1.5;
  color: #fff;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
}

.loginBtn:hover {
  background: #34a853;
  border-color: #34a853;
  transform: scale(1.1);
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.8px;
  font-family: 'Segoe UI';

  height: 33px;
  padding: 3px 8px;
  border-radius: 5px;
  line-height: 1.5;
  color: #fff;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
}

.loginBtn_New {
  background-color: #002663 !important;
  border-color: #002663 !important;
}

.addNew {
  background-color: #002663 !important;
  border-color: #002663 !important;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.8px;
  font-family: 'Segoe UI';
  /* text-transform: uppercase; */
  height: 33px;
  padding: 3px 8px;
  border-radius: 5px;
  line-height: 1.5;
  color: #fff;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
}
.addNew:disabled {
  background-color: #1f3d6da9 !important;
  border-color: #1f3d6da9 !important;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.8px;
  font-family: 'Segoe UI';
  /* text-transform: uppercase; */
  height: 33px;
  padding: 3px 8px;
  border-radius: 5px;
  line-height: 1.5;
  color: #fff;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;

  white-space: nowrap;

  vertical-align: middle;

  cursor: no-drop;

  user-select: none;
  border: 1px solid transparent;
}

.loginBtn1:enabled {
  /* background: #34a853;
  border-color: #34a853; */
  background: rgba(0, 38, 99, 1) !important;
  border-color: rgba(0, 38, 99, 1) !important;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.8px;
  font-family: 'Segoe UI';
  text-transform: capitalize;
  height: 33px;
  padding: 3px 8px;
  border-radius: 5px;
  line-height: 1.5;
  color: #fff;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
}

.loginBtn1:disabled {
  background: rgba(0, 38, 99, 0.5) !important;
  border-color: rgba(0, 38, 99, 0.5) !important;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.8px;
  font-family: 'Segoe UI';
  text-transform: capitalize;
  height: 33px;
  padding: 3px 8px;
  border-radius: 5px;
  line-height: 1.5;
  color: #fff;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: no-drop;
  user-select: none;
  border: 1px solid transparent;
}

.loginBtn1:enabled:hover {
  background: #34a853;
  border-color: #34a853;
  transform: scale(1.1);
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.8px;
  font-family: 'Segoe UI';
  text-transform: capitalize;
  height: 33px;
  padding: 3px 8px;
  border-radius: 5px;
  line-height: 1.5;
  color: #fff;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
}

:not(.btn-check) + .btn:hover,
.btn:first-child:hover {
  background: #34a853;
  border-color: #34a853;
  font-size: 13px;
  transform: scale(1.1);
  font-weight: 600;
  letter-spacing: 0.8px;
  font-family: 'Segoe UI';
  /* text-transform: uppercase; */
  height: 33px;
  padding: 3px 8px;
  border-radius: 5px;
  line-height: 1.5;
  color: #fff;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
}

.reset {
  background: rgba(255, 0, 0, 1) !important;
  border-color: rgba(255, 0, 0, 1) !important;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.8px;
  font-family: 'Segoe UI';
  text-transform: capitalize;
  height: 33px;
  padding: 3px 8px;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
}

.reset:hover {
  background: #ea4335 !important;
  border-color: #ea4335 !important;
  transform: scale(1.1);
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.8px;
  font-family: 'Segoe UI';
  text-transform: capitalize;
  height: 33px;
  padding: 3px 8px;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
}

/*clear  */

.clear {
  background: rgba(204, 204, 204, 1) !important;
  border-color: rgba(204, 204, 204, 1) !important;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.8px;
  font-family: 'Segoe UI';
  /* text-transform: uppercase; */
  height: 33px;
  padding: 3px 8px;
  border-radius: 5px;
  color: #000000;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  /* cursor: pointer; */
  user-select: none;
  border: 1px solid transparent;
}

.clear:hover {
  background: rgba(204, 204, 204, 1) !important;
  border-color: rgba(204, 204, 204, 1) !important;
  transform: scale(1.1);
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.8px;
  font-family: 'Segoe UI';
  text-transform: capitalize;
  height: 33px;
  padding: 3px 8px;
  border-radius: 5px;
  color: #000;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
}
.save {
  /* background: rgba(0, 38, 99, 1) !important; */
  /* border-color: rgba(0, 38, 99, 1) !important; */
  background: rgba(78, 165, 81, 1) !important;
  border-color: rgba(78, 165, 81, 1) !important;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.8px;
  font-family: 'Segoe UI';
  text-transform: capitalize;
  height: 33px;
  padding: 3px 8px;
  border-radius: 5px;
  color: #ffffff;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  /* cursor: pointer; */
  user-select: none;
  border: 1px solid transparent;
}

.save:hover {
  background: rgba(78, 165, 81, 1) !important;
  border-color: rgba(78, 165, 81, 1) !important;
  transform: scale(1.1);
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.8px;
  font-family: 'Segoe UI';
  text-transform: capitalize;
  height: 33px;
  padding: 3px 8px;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
}

.save:disabled {
  /* background: rgba(0, 38, 99, 1) !important; */
  /* border-color: rgba(0, 38, 99, 1) !important; */
  background: rgba(78, 165, 81, 0.5) !important;
  border-color: rgba(78, 165, 81, 0.5) !important;
  /* font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.8px;
  font-family: 'Segoe UI';
  text-transform: capitalize;
  height: 33px;
  padding: 3px 8px;
  border-radius: 5px;
  color: #ffffff;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation; */
  cursor: not-allowed;
  user-select: none;
  border: 1px solid transparent;
}

.update {
  background: #34a853;
  border-color: #34a853;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.8px;
  font-family: 'Segoe UI';
  /* text-transform: uppercase; */
  height: 33px;
  padding: 3px 8px;
  border-radius: 5px;
  line-height: 1.5;
  color: #fff;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
}

.update:disabled {
  background: rgba(78, 165, 81, 0.5) !important;
  border-color: rgba(78, 165, 81, 0.5) !important;
  cursor: not-allowed;
  user-select: none;
  border: 1px solid transparent;
}
button.btn.btn-primary.pdfbtns {
  background: rgba(0, 38, 99, 0.8) !important;
  border-color: rgba(0, 38, 99, 0.8) !important;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.8px;
  font-family: 'Segoe UI';
  text-transform: capitalize;
  height: 33px;
  padding: 3px 8px;
  border-radius: 5px;
  color: #ffffff;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  /* cursor: pointer; */
  user-select: none;
  border: 1px solid transparent;
}
/* button.btn.btn-primary.pdfbtns:hover {
  background: #000;
  border-color: red;
} */

.mright {
  margin-right: 10px;
}

.css-319lph-ValueContainer:focus {
  border: 1px none solid;
  box-shadow: none;
}

.font_Title {
  font-weight: 600;
  opacity: initial;
  font-family: 'Nunito Sans' !important;
  letter-spacing: 0.5px;
  margin-top: 0;
  font-size: 20px;
  margin-bottom: 20px;
  /* rajesh */
  color: #002247;
  text-transform: capitalize !important;
  /* padding: 5px 7px; */
  width: 100%;
  line-height: 1.1;
  pointer-events: none;
}

.font-size {
  font-weight: 600;
  opacity: initial;
  font-family: 'Nunito Sans' !important;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  margin-top: 0;
  font-size: 16px !important;
  margin-bottom: 0;
  /* rajesh */
  color: black;
  padding: 5px 0px;
  width: 100%;
  line-height: 1.1;
}

.panel-heading {
  /* rajesh */
  /* background-color: #0b488e !important; */
  border-color: #0b488e !important;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.12); */
  /* height: 44px;
    font-size: 14px; */
  padding: 5px 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.col-form-label {
  font-size: 14px !important;
}

.d-inline {
  font-family: 'Nunito Sans' !important;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  color: #fff;
  width: 100%;
  line-height: 1.1;
  font-weight: bold;
  font-size: 14px;
  text-align: center !important;
  display: flex;
  justify-content: center !important;
  align-items: center;
}
/*
  .d-inline {
    font-family: 'Nunito Sans' !important;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    color: #000;
    width: 100%;
    line-height: 1.1;
    font-weight: bold;
    font-size: 14px;
    text-align: center !important;
    display: flex;
    justify-content: center !important;
    align-items: center;
  } */

th {
  vertical-align: middle;
  overflow: hidden;
  cursor: pointer;
  text-align: center !important;
}

table {
  border: 1px solid #bababa;
  border-radius: 10px !important;
}

.table > tbody > tr > td:first-child {
  text-align: center;
}

/* jai */

/* .form-control:focus {
  border: 4px solid rgb(11 72 142) !important;
  box-shadow: none !important;
} */

input.date-picker::focus {
  width: 100%;
  border-radius: 5px;
  height: 38px;
  border: 1px solid;
  border-color: rgb(11 72 142) !important;
  padding: 10px;
}

.css-1s2u09g-control::focus {
  border-color: rgb(11 72 142) !important;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 95vw !important;
}

/* .css-1s2u09g-control:focus {
  box-shadow: none !important;
} */

.css-1pahdxg-control {
  border-color: rgb(11 72 142) !important;
  box-shadow: none !important;
}

.css-1pahdxg-control:hover {
  border-color: rgb(11 72 142) !important;
  box-shadow: none !important;
  /* border-color:#b1b7c1 !important; */
}

.ItemsPerPage {
  position: absolute;
  display: flex;
  top: 60px;
  right: 35px;
  cursor: pointer;
}

.ItemsPerPage_New {
  bottom: 30px !important;
  top: auto !important;
}

.donlabel {
  margin-bottom: 5px;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  padding-top: 8px;
  font-family: 'Segoe UI Semibold';
}

.table-#fff fw-semibold {
  background-color: #fff !important;
  text-align: center;
}

.table-responsive {
  border-radius: 10px !important;
  text-align: center;
}

a.page-link {
  color: #002663 !important;
}

.page-link {
  border: 1px solid #092460 !important;
}

.form-select {
  border: 1px solid #092460 !important;
}

.filterContainer {
  margin-top: 0px !important;
}

.filterContainer_New {
  margin-top: 0px !important;
}

/* input,
input::placeholder {
  font-size: 12px;
} */

.sidebar-nav {
  position: relative;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 0px 0px !important;
  margin-bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
}

@media (min-width: 768px) {
  .donlabel {
    margin-bottom: 5px;
    color: #000;
    font-weight: 500;
    font-size: 14px;
    padding-top: 8px;
    font-family: 'Segoe UI Semibold';
  }
}
.table-#fff fw-semibold {
  background-color: #fff !important;
  text-align: center;
}

/* .form-control {
  color: #111 !important;
  height: 35px !important;
  padding: 1px 10px !important;
  border-radius: 5px !important;
  background-color: #fff !important;
  box-shadow: none !important;
} */
.form-control {
  color: #111 !important;
  height: 35px !important;
  padding: 1px 10px !important;
  border: 1px solid #cbcbcb !important;
  border-radius: 5px !important;
  background-color: #fff !important;
  box-shadow: none !important;
  font-size: 1rem !important;
}
/* .form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
} */
/* input,
input::placeholder {
  font-size: 12px;
} */

.sidebar-nav {
  position: relative;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 0px 0px !important;
  margin-bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
}

@media (min-width: 768px) {
  .donlabel {
    margin-bottom: 0;
    text-align: right;
    color: #000;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Segoe UI Semibold';
  }

  .onenoteight {
    margin-top: -120px !important;
  }
}

.pageid {
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 10px;
}

.inputfieldgo {
  font-size: 16px !important;
  margin-top: 5px;
}

.inputfieldgo:focus {
  font-size: 16px !important;
  margin-top: 5px;
  /* border: 2px solid #2684ff !important; */
  border: 1px solid #002145 !important;
}

.css-t3ipsp-control {
  border: 1px solid #002145 !important;
  box-shadow: none !important;
}

.css-13cymwt-control:focus {
  border: 1px solid #002145 !important;
}

.inputfieldLogin {
  font-size: 12px !important;
  margin-top: 0px;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  /* background-color: #EEEEEE; */
}

.inputfieldgen {
  font-size: 14px !important;
  margin-top: 5px;
}

.editflex {
  margin-left: 50px !important;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  /* background-color: #eeeeee; */
  text-transform: none !important;
}

.hoverblock {
  visibility: hidden;
}

.cardView_male:hover .hoverblock {
  visibility: visible !important;
}

.cardView_male {
  cursor: pointer;
}

.variableaddbtn {
  background-color: #0c97ec;
  border-color: #0c97ec;
  color: #fff;
  border-radius: 5px;
  margin-top: 4px;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  /* background-color: #eeeeee; */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  /* background-color: #eeeeee; */
  text-transform: none !important;
}

.PdfTable {
  padding: 30px;
}

.PdfTableHeader {
  font-size: 16px;
  font-weight: 600;
  font-family: Segoe UI Semibold;
  margin: 0 auto;
  text-transform: capitalize;
  width: 100%;
  text-align: center;
  padding-top: 10px;
  display: flex;
  justify-content: space-evenly;
}

.PdfButton {
  width: fit-content;
  display: flex;
  align-items: center;
  padding-left: 90%;
  padding-top: 10px;
}

.printme {
  display: none !important;
}

@media print {
  .no-printme {
    display: none !important;
  }

  .printme {
    display: block !important;
  }
}

.LogoPDFPicture {
  width: 50px;
  height: 50px;
  /* position: relative;
left: 60px;
top:-20px; */
}

.pdfModelTitle {
  font-size: 14px;
}

.pdfModelTitle1 {
  font-size: 16px;
}

.retporttabledetail {
  font-size: 12px;
}

.TableDownload .tooltipDownload {
  visibility: hidden;
  width: 100px;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 4px;
  padding: 1px 0;
  font-weight: 500;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 40px;
  margin-left: -10px;
  border: 1px solid;
}

.TableDownload:hover .tooltipDownload {
  visibility: visible;
}

.printModelbtn {
  color: #fff;
  background-color: #4285f4;
  border-color: #4285f4;
  padding: 8px 10px !important;
  border-radius: 5px;
  font-size: 14px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  position: absolute;
  left: 86%;
  top: 10px;
  text-align: center;
  display: flex !important;
  justify-content: space-around;
}

.printModelbtn:hover {
  color: #fff;
  background-color: #34a853;
  border-color: #34a853;
  padding: 8px 10px !important;
  border-radius: 5px;
  font-size: 14px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  position: absolute;
  left: 86%;
  top: 10px;
  text-align: center;
  display: flex !important;
  justify-content: space-around;
}

.pdfbtns {
  color: #fff;
  background-color: #4285f4;
  border-color: #4285f4;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.pdfbtns:hover {
  color: #fff;
  background-color: #34a853;
  border-color: #34a853;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.pdfbtnsDwn {
  color: #fff;
  background-color: #4285f4;
  border-color: #4285f4;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.pdfbtnsDwn:hover {
  color: #fff;
  background-color: #34a853;
  border-color: #34a853;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Print */

@media screen {
  #printSection {
    display: none;
  }
}

/* @media print {
  body * {
    visibility:hidden;
  }
  #printSection, #printSection * {
    visibility:visible;
  }
  #printSection {
    position:absolute;
    left:0;
    top:0;
  }
} */

/* Print end */

.subhead {
  font-size: 12px;
  font-weight: 600;
  margin-right: 10px;
}

.username {
  font-size: 10px;
  text-transform: capitalize;
}

.table {
  font-size: 14px !important;
  text-align: center;
}

img {
  font-size: 12px;
}

td {
  text-transform: lowercase !important;
}

td::first-letter {
  text-transform: capitalize;
}

.textarea {
  height: 75px !important;
}

.flex_row {
  display: flex;
  flex-direction: row;
  position: relative;
  top: -30px !important;
  left: 20px;
}

#Text {
  text-align: left !important;
}

.multiSelectContainer li:hover {
  background: #0096fb !important;
  color: #fff;
  cursor: pointer;
}

.multiSelectContainer li {
  background: #fff !important;
  color: #000;
  cursor: pointer;
}

.chip {
  align-items: center;
  background: #0096fb !important;
  border-radius: 11px;
  color: #fff;
  display: inline-flex;
  font-size: 13px;
  line-height: 19px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 4px 10px;
}

li.nav-item {
  position: relative;
  right: 12px;
}

.sidebar-nav .nav-group {
  position: relative;
  transition: background 0.15s ease-in-out;
  overflow: hidden !important;
}

/*Task Decription page   */
.ItemsPerPageMappage {
  position: absolute;
  display: flex;
  right: 35px;
  justify-content: flex-end;
  cursor: pointer;
}

.TableDownloadmapPage {
  position: absolute;
  display: flex;
  /* top: 60px; */
  right: 10px;
  cursor: pointer;
  justify-content: flex-end;
}

.TabelOver {
  overflow: auto;
}
.escols {
  color: #002247 !important;
  text-align: left !important;
  font-size: 20px;
  padding: 3px 0px !important;
}
.escolsTitle {
  color: #0d6efd;
  font-size: 28px;
}
.txtransform {
  text-transform: lowercase !important;
}

.position-relative {
  /* position: relative !important;
  overflow: auto;
  border-radius: 10px;
  border-bottom-right-radius: 38px;
  border-bottom-left-radius: 38px; */
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}

.userName {
  text-transform: capitalize;
  color: #0b488e;
}

.testareas {
  padding-left: 50px !important;
  padding-top: 7px !important;
}

.center {
  text-align: center !important;
}

.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 6px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

input.react-datepicker-ignore-onclickoutside {
  width: 100% !important;
  border-radius: 2px;
  height: 38px;
}

.sixtysixgap {
  padding-left: 66px !important;
}

.text-align-left {
  text-align: left !important;
}

.text-align-right {
  text-align: right !important;
}

.text-align-center {
  text-align: center !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff;
}

.Toastify__toast-body {
  text-transform: initial;
}

/* .table th {
  background-color: #4f5d73!important;
  color: #fff!important;
}
.d-inline {
  color: #fff!important;
} */

.font_Title_New {
  color: #0b488e !important;
  background-color: #f8f9fa !important;
  font-weight: 600 !important;
  /* width: 100% !important; */
  font-size: 18px !important;
  text-transform: capitalize;
  padding: 10px;
}

.Border_New {
  border: 1px solid #bababa;
}
svg.icon.icon-custom-size {
  color: rgb(255, 255, 255);
}
table.EmployeeList th {
  background: #002247;
  color: #fff;
  text-align: center;
}
.donlabelLeft {
  text-align: left;
  font-weight: 500;
}
/* .small{
  background-color: red;
}
.big{
  background-color: green;
} */
.textalign {
  text-align: left;
}
span b {
  text-transform: capitalize;
}
.filepath {
  display: grid;
  padding: auto;
  margin: auto;
}
.PasswordVis {
  position: relative;
  z-index: 99999;
  left: -30px;
  top: 5px;
  background: none;
  border: 0;
  font-size: 18px;
  color: #616161;
}

.activebtn {
  cursor: pointer;
  color: green;
  font-size: 35px;
}
.activebtn.disabled {
  cursor: no-drop;
  color: rgb(122, 146, 122);
  font-size: 35px;
}
.Deactivebtn {
  cursor: pointer;
  color: red;
  font-size: 35px;
}
.Deactivebtn.disabled {
  cursor: no-drop;
  color: rgb(129, 99, 99);
  font-size: 35px;
}
.assigntext {
  position: relative;
  top: 40px;
  color: rgba(0, 38, 99, 1);
  font-weight: bold;
  cursor: pointer;
}
/* #react-select-2-listbox,
#react-select-1-listbox,
#react-select-3-listbox,#react-select-4-listbox,
#react-select-5-listbox,#react-select-6-listbox,
#react-select-7-listbox,#react-select-8-listbox   
{
  z-index: 999999999999 !important;
  position: relative;
  width: 120px;
} */

/* #react-select-2-container option{
 
  z-index: 999999999999 !important;
  position: relative;
}
.css-1nmdiq5-menu{
  z-index: 999999999999 !important;
  position: relative;
} */

/* Pre - Loader - Loading... */

.waviy {
  position: relative;
  /* margin: 15px;
  padding: 10px; */
  /* background-color: #000000; */
  -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
}

.waviy span {
  position: relative;
  display: inline-block;
  margin-top: 10px;
  font-size: 20px;
  font-weight: 700;
  /* background-color: #000; */
  color: #002247;
  padding: 5px;
  text-transform: uppercase;
  animation: waviy 1s infinite;
  animation-delay: calc(0.1s * var(--i));
}

@keyframes waviy {
  0%,
  40%,
  100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-20px);
  }
}

/* .css-13cymwt-control {
  width: 135px !important;
}
.css-t3ipsp-control {
  width: 135px !important;
} */
