/* responsive  */
@media (min-width: 768px) {
  html:not([dir=rtl]) .sidebar.hide:not(.sidebar-end) {
    margin-left: 0;
  }


  .header-toggler:not(:disabled) {
    cursor: pointer;
    color: #000;
    margin-left: -15px;
    background-color: aqua;
    display: contents !important;
  }
}


/* Cell Phone */
@media (min-width: 320px) and (max-width: 768px){
/* header -  Company name  */
  .textCenter {
    font-size: 11px;
    font-weight:700;
    font-family: Segoe UI Semibold;
    display: flex;
    justify-content: flex-start;
      position: absolute;
    left: 35px;
    color: #0b488e;
  }

.ItemsPerPage, .ItemsPerPageMappage{
    display: none;
 }

.userBtnCellView {
  position: relative;
  left: 15px;
}

/* login Button */
.loginBtnRS {
  display: flex !important;
  justify-content: flex-end !important;
  
}

/* Footer section  */
.FooterText {
  font-size: 11px;
  display: flex;
  justify-items: center;
    margin: 0;

}
.FootercopyRight {
  font-size: 11px; 
   display: flex;
  justify-items: center;
  margin: 0;
}
  
.flex_row {
  display: block;
  /* flex-direction: row; */
 position: relative;
  top: 1px !important;
  left: 1px; 

}
.leftCols {
display: inline-flex !important;
background-color: #f7f7f7;
border: 1px solid #d6cfcf;
padding: 10px 15px 10px 10px !important;
margin: 0px 5px !important;
}
input.date-picker {
  width: 100%;
  border-radius: 5px;
  height: 38px;
  border: 1px solid #dddddd;
  padding: 0px;
  margin: 0 auto !important;
}
.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
  padding-left: 2rem !important;
}
.PdfButton {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  padding-left: 70% !important;
  padding-top: 10px;
}
}

/* TAB */

@media (min-width: 576px)
{
  .col-sm-3 {
    flex: 0 0 auto;
    width:33%;
}}
@media (min-width: 768px) and (max-width: 1024px){
  .login {
    margin: 0 -100px;
    border-radius: 5px;
    box-shadow: 0 4px 12px;
  }

  .flex_row {
    display: block;
    margin-left: 5px;
    /* flex-direction: row; */
    /* position: relative; */
    /* top: 1px !important; 
    left: 1px;  */
}
.donlabelTab {
  margin-bottom: 0;
  text-align: left;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  font-family: 'Segoe UI Semibold';}
label.form-label.col-sm-3.col-form-label.donlabel {
    width:100%;
  text-align: left;
}
.header.header-sticky {
  position: sticky;
  top: 0;
  z-index: 1029;
}
.textCenter {
  font-size: 14px !important;
  font-weight: 600;
  font-family: Segoe UI Semibold;
  display: flex;
  justify-content: start;
  position: absolute;
  left: 75px !important;
}

  .donlabelTab {
    margin-bottom: 0;
    text-align: left;
    color: #000;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Segoe UI Semibold';
  }

  label.form-label.col-sm-3.col-form-label.donlabel {
    width: 100%;
    text-align: left;
  }

  .header.header-sticky {
    position: sticky;
    top: 0;
    z-index: 1029;
  }

  .pdfbtnsDwn {
    color: #fff;
    background-color: #4285F4;
    border-color: #4285F4;
    padding: 0px 15px;
    position: relative;
    /* left: -58px; */
    right: 60px;
    border-radius: 5px;
    font-size: 14px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

  .DesignationForm {
    display: flex !important;
    flex-direction: column !important;

  }

}

/* Big Screen */

@media (min-width: 1920px) and (max-width: 7000px) {
  .bg-lightLogin {
    background: url(../src/assets/images/backgroung.png) center center no-repeat fixed;
    margin-bottom: auto;
    margin-top: auto;
    background-size: cover !important;
    background-position: center; 
    background-repeat: no-repeat;
  }
}



/* Cell Phone */
@media (min-width: 280px) and (max-width:768px) and (orientation: portrait) {

  /* login page */

  .logoPictureCell {
    display: none;
  }

  .webcomp {
    display: none;
  }

  /* end */

  /* Header section */
  /* .logoPictureCell{
position: relative;
left: -10px;
}
 */
  .textCenter {
    font-size: 11px;
    font-weight: 600;
    font-family: Segoe UI Semibold;
    display: flex;
    justify-content: start;
    position: absolute;
    left: 35px;
  }

  /* Footer section  */
 
  /*Footer section End  */

  /* input.react-datepicker-ignore-onclickoutside {
    width: auto;
    border-radius: 2px;
    height: auto;
    border: 0px solid #dddddd;
  }

  input.date-picker {
    width: auto;
    border-radius: 2px;
    height: auto;
    border: 1px solid #dddddd;
  } */

  .container-fluid.BreadcurmTitle {
    margin-left: 10px;
  }
}



/* Tablet */
/* @media (min-width: 481px) and (max-width: 1200px) and (orientation: landscape) {
  
} */

/* android tab  */
/* @media (min-width: 481px) and (max-width: 980px) and (orientation: landscape) {
} */

/* New - 10.10.2022 */

@media (min-device-width: 481px) and (max-device-width: 768px) {
  .FooterText {
    font-size: 12px;
    display: contents;
  }

  .FootercopyRight {
    font-size: 12px;
    display: contents;
  }

  input.react-datepicker-ignore-onclickoutside {
    width: 250px;
    border-radius: 2px;
    height: 38px;
    border: 0px solid #dddddd;
  }

  input.date-picker {
    width: 250px;
    border-radius: 2px;
    height: 38px;
    border: 1px solid #dddddd;
  }

  /* .leftCols {
    background-color: #f7f7f7;
    border: 1px solid #d6cfcf;
    padding: 10px 10px 0px 20px !important;
    margin: 0px 5px;
  } */
  .container-fluid.BreadcurmTitle {
    margin-left: 10px;
  }

}

@media screen and (min-device-width: 1025px) and (max-device-width: 1200px) {
  /*
    input.react-datepicker-ignore-onclickoutside {
      width: 250px;
      border-radius: 2px;
      height: 38px;
      border: 0px solid #dddddd;

    }

    input.date-picker {
      width: 250px;
      border-radius: 2px;
      height: 38px;
      border: 1px solid #dddddd;
    }*/
}

@media screen and (min-device-width: 1201px) and (max-device-width: 1400px) {}


/* @media screen and (min-device-width: 1920px) and (max-device-width: 5110px) {
  .icon {
    display: inline-block !important;
    color: inherit !important;
    text-align: center !important;
    vertical-align: -0.125rem !important;
    fill: currentColor !important;
    background-color: red !important;
    position: relative !important;
    left: 100px !important;
  }
  .icon:not(.icon-c-s):not(.icon-custom-size).icon-lg {
    width: 1.25rem;
    height: 1.25rem;
    font-size: 1.25rem;
    position: relative !important;
    left: 100px !important;
    background-color: red;
}
.header-toggler:not(:disabled) {
  cursor: pointer !important;
  position: relative !important;
  left: 100px !important;
  background-color: red;
z-index: 999999;
}
} */