.employeeHeader {
  font-weight: 500;
  color: #001a37;
  font-size: 20px;
}

.btnSubmitSave {
  padding: 5px 10px !important;
  border-radius: 3px !important;
  margin: 10px 10px !important;
  width: 100px !important;
  background: #34a853 !important;
  border-color: #34a853 !important;
}

.btnSubmitCancel {
  padding: 5px 10px !important;
  border-radius: 3px !important;
  margin: 10px 10px !important;
  width: 100px !important;
  background: #ea4335 !important;
  border-color: #ea4335 !important;
}

.btnAlign {
  float: right;
}

.leftCols {
  padding: 10px 0px 0px 50px;
  margin: 0px 5px;
}

.Address {
  background-color: #f7f7f7;
  border-top: 1px solid #d6cfcf;
}

input.react-datepicker-ignore-onclickoutside {
  width: 583px;
  border-radius: 2px;
  height: 38px;
  border: 0px solid #dddddd;
}

input.date-picker {
  width: 100%;
  border-radius: 5px;
  height: 38px;
  border: 1px solid #dddddd;
  padding: 10px;
}

input.react-datepicker-ignore-onclickoutside {
  width: 583px;
  border-radius: 2px;
  height: 38px;
  border: 0px solid #dddddd;
}

input.date-picker1 {
  width: 620px;
  border-radius: 2px;
  height: 38px;
  border: 1px solid #dddddd;
}

.DesignationForm {
  /* margin:0 auto !important; */
  /* background-color: #f7f7f7; */
  display: flex;
  justify-content: center;
  margin: 15px auto;
}

/* .dashboardWidth{
  width:1100px;

  } */

.card{
  box-shadow: 2px 6px 10px 0px rgba(2, 51, 52, 0.05);
  border: 0px !important;
}

